import { apiCall } from './apiHelper';

// Private routes
export const createShop = apiCall('post', '/api/v1/shop/create', true);
export const getShopInfo = apiCall('get', '/api/v1/shop', true);
export const updateShopInfo = (shopId, data) => apiCall('put', `/api/v1/shop/update/${shopId}`, true)(data);

// Public routes
export const getShopByShopUrlFriendlyName = (shop_urlfriendlyname) => apiCall('get', `/api/v1/shop/${shop_urlfriendlyname}`, true)(); // Notice the extra () at the end; this will ensure that the returned function from apiCall gets invoked immediately, and you get a promise.



