import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getPublicUserInfoByUrlFriendlyName } from '../../../api/user';
import { Helmet } from 'react-helmet';
import { config } from '../../../config'; // Required to retrieve config.upload_url URL


const UserPage = () => {
  const { user_urlfriendlyname } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPublicUserInfoByUrlFriendlyName(user_urlfriendlyname)();
        if (response && response.success) {
          setUser(response.user);
        } else {
          setUser('NotFound');
          console.error("Error:", response ? response.messages : 'Response is undefined');
        }
      } catch (err) {
        console.error("Caught an error while fetching data:", err);
      }
    };

    fetchData();
  }, [user_urlfriendlyname]);

  if (!user) {
    return <div>Loading...</div>;
  }

  if (user === 'NotFound') {
    return <div>User not found</div>;
  }

  return (
    <>
      <Helmet>
        <title>{user && user.username}</title>
        <meta name="description" content={`Vous consultez actuellement la liste des boutiques de ${user_urlfriendlyname}.`} />
      </Helmet>

		<div id ="shop-page">
			<div id ="shop-header">
			<div id ="banner">
	           {user.image_banner ? (
	              <img src={`${config.upload_url}/${user.image_banner}`} alt="Banner" />
	            ) : (
	              <img src={`${config.upload_url}/missing_banner.jpg`} alt="Missing banner" />
	            )}
			</div>
			<div id = "subheader">
				{user.image_logo ? (
	              <img src={`${config.upload_url}/${user.image_logo}`} alt="Logo" />
	            ) : (
	              <img src={`${config.upload_url}/missing_logo.jpg`} alt="Missing logo" />
	            )}
	            <div id = 'username'>{user.username}</div>
			</div>
		</div>
	
	      <div id="shop-body">
		      <section id="first-section">
		          <h2>Sélectionnez une catégorie</h2>
		      </section>
		      <section id="second-section">
				<div className = "element-container">
			          <div className="element menu">
			            {user.shops.map(shop => (
			                <Link key={shop.id} to={`./${shop.name_urlfriendly}`}>{shop.name}</Link>
			            ))}
			          </div>
			      </div>
		      </section>
			</div>
	</div>
    </>
  );
};

export default UserPage;
