import React, { useEffect, useState } from 'react';
import { getOrderInfo } from '../../../api/order';

const OrdersList = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await getOrderInfo();
        if (response.success) {
          setOrders(response.orders);
        } else {
          // Handle error here
          console.error(response.messages);
        }
      } catch (error) {
        // Handle error here
        console.error(error);
      }
    };

    fetchOrders();
  }, []);

  return (
	<div className = "element-container">
      {orders.map((order) => (
        <div key={order.id} className="element">
            <p>Order ID: {order.id}</p>
            <p>Status: {order.status}</p>
            <p>Créée a: {new Date(order.created_at).toLocaleString()}</p>
        </div>
      ))}
    </div>
  );
};

export default OrdersList;
