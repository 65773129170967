import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getUserInfo } from '../../api/user';
import LogOut from '../core/LogOut';
import { ROUTE_ADMIN_PRODUCTS, ROUTE_ADMIN_ORDERS, ROUTE_ADMIN_PROFIL } from '../../utils/routes';
import { Helmet } from 'react-helmet';

const Menu = () => {
  const [usernameUrlFriendly, setUsernameUrlFriendly] = useState('');

  useEffect(() => {
    getUserInfo()
      .then((response) => {
        setUsernameUrlFriendly(response.user.username_urlfriendly);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  return (
	<>
		<Helmet>
			<title>Accueil</title>
		</Helmet>
		<section id="first-section">
			<h1>Accueil</h1>
			<h2>Gérez votre compte.</h2>
		</section>
		<section id="second-section">
			<div className = "element-container">
				<div className="element menu">
					<Link to={`/${usernameUrlFriendly}`}>Affichez votre boutique</Link>
					<Link to={ROUTE_ADMIN_PRODUCTS}>Produits</Link>
					<Link to={ROUTE_ADMIN_ORDERS}>Commandes</Link>
					<Link to={ROUTE_ADMIN_PROFIL}>Profil</Link>
					<LogOut />
				</div>
			</div>
		</section>
    </>
  );
};

export default Menu;
