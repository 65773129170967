import axios from 'axios';
import { getCookie } from '../utils/cookie';
import { config } from '../config';

const apiCall = (method, url, needsAuth) => {
  return async (data = null) => {
    try {
      // Determine content type based on data type
      let contentType = 'application/json';
      if (data instanceof FormData) {
        contentType = undefined;  // Let the browser set it for FormData
      }

      // Common headers
      const headers = {
        'Content-Type': contentType,
      };

      // Add authorization if the route needs authentication
      if (needsAuth) {
        headers['Authorization'] = `Bearer ${getCookie('auth_token')}`;
      }

      const response = await axios({ method, url: `${config.api_url}${url}`, data, headers });
      return response.data;
    }
    catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data); // Debugging
        return { success: false, messages: err.response.data.messages };
      } else {
        console.log('Erreur non prise en charge'); // Debugging
      }
    }
  };
};

export { apiCall };
