import React from 'react';
import { Link } from 'react-router-dom';
import ProductsEdit from './ProductsEdit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ROUTE_ADMIN, ROUTE_ADMIN_PRODUCTS_ADD } from '../../../utils/routes';
import { Helmet } from 'react-helmet';

const ProductsPage = () => {
	  return (
	    <>
			<Helmet>
				<title>Mes produits</title>
			</Helmet>
			<section id="first-section">
				<h1>Produits</h1>
				<h2>Gérez vos produits.</h2>
				<Link to={ROUTE_ADMIN_PRODUCTS_ADD}><button>Ajouter un produit</button></Link>
			</section>
			<section id="second-section">
				<ProductsEdit />
			</section>
		</>
	  );
};

export default ProductsPage;
