import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTE_ADMIN } from '../../../utils/routes';
import { Helmet } from 'react-helmet';

const Step3 = () => {
  return (
    <>
	      <Helmet>
				<title>Félicitation !</title>
	      </Helmet>
		<section id="first-section">
          <h1>Félicitation !</h1>
          <h2>Votre boutique est en ligne.</h2>
      </section>
		<section id="second-section">
					<div className = "element-container">
          <Link to={ROUTE_ADMIN}>
            <button>Accéder à mon compte</button>
          </Link>
        </div>
      </section>
    </>
  );
};

export default Step3;
