import { apiCall } from './apiHelper';

// Private routes
export const createProduct = apiCall('post', '/api/v1/product/create', true);
export const getProductInfo = apiCall('get', '/api/v1/product', true);
export const updateProductInfo = (productId, data) => apiCall('put', `/api/v1/product/update/${productId}`, true)(data);
export const deleteProductInfo = (productId) => apiCall('delete', `/api/v1/product/delete/${productId}`, true)({}); // Empty JSON must be sent, otherwise the following error happens : "Unexpected end of JSON input"

// Public route
export const searchProducts = (data) => apiCall('post', '/api/v1/product/search', true)(data);  // Used to display products in user public shops
