import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { searchProducts } from '../../../api/product'; 
import { getShopByShopUrlFriendlyName } from '../../../api/shop'; // Used to retrieve shop information (shop name)
import { getPublicUserInfoByUrlFriendlyName } from '../../../api/user'; // State to retrieve user information (user name)
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ROUTE_CART } from '../../../utils/routes';
import { config } from '../../../config'; // Required to retrieve config.upload_url URL
import { CartContext } from '../../CartContext';  // import CartContext
import { Helmet } from 'react-helmet';

function ShopPage() {
	const { user_urlfriendlyname, shop_urlfriendlyname } = useParams(); // Retrieve url values
	const [products, setProducts] = useState([]); // State for storing products
	const [shop, setShop] = useState(null); // State to retrieve shop information (shop name)
	const [user, setUser] = useState(null); // State to retrieve user information (user name)
	const [message, setMessage] = useState('Loading...'); // State for displaying loading/error message
	//	const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || {}); // State for cart items
	const [cart, setCart] = useContext(CartContext);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPublicUserInfoByUrlFriendlyName(user_urlfriendlyname)(); 

        if (response && response.success) {
          setUser(response.user);
        } else {
          setUser('NotFound');
          console.error("Error:", response ? response.messages : 'Response is undefined');
        }
      } catch (err) {
        console.error("Caught an error while fetching data:", err);
      }
    };

    fetchData();
  }, [user_urlfriendlyname]);



  useEffect(() => {
  	
    // Log the payload before sending
    console.log("Sending payload to searchProducts:", { user_urlfriendlyname, shop_urlfriendlyname });
  	
    // Fetch products when the shop_urlfriendlyname parameter changes
    searchProducts({ user_urlfriendlyname, shop_urlfriendlyname })

      .then((productsResponse) => {
        if (productsResponse && productsResponse.length > 0) {
          // If products are found, update the state
          setProducts(productsResponse);
          setMessage(null); // Clear the loading/error message
        } else {
          setMessage('Aucun produit trouvé dans cette boutique.'); // Set message when no products are found
        }
      })
      .catch(() => {
        setMessage('An error occurred while fetching the products'); // Set message in case of an error
      });
  }, [shop_urlfriendlyname]);

  useEffect(() => {
    getShopByShopUrlFriendlyName(shop_urlfriendlyname)
      .then(response => {
        if (response && response.shop) {
          setShop(response.shop);
          console.log('Shop Name:', response.shop.name); 
        } else {
          console.error(response.message);
        }
      })
      .catch(error => {
        console.error('Error fetching shop details:', error);
      });
  }, [shop_urlfriendlyname]);


  const addToCart = (userId, productId, availableQuantity) => {
    if (!cart[userId]) {
      cart[userId] = []; // Initialize the user's cart if it doesn't exist
    }
    const productIndex = cart[userId].findIndex((item) => item.productId === productId);
    if (productIndex !== -1) {
      if (cart[userId][productIndex].quantity < availableQuantity) {
        cart[userId][productIndex].quantity += 1; // Increase quantity if it's below the available quantity
      } else {
        alert('Quantité max. atteinte pour ce produit.'); // Show alert if the maximum quantity is reached
        return;
      }
    } else {
      cart[userId].push({ productId, quantity: 1 }); // Add the product to the user's cart with quantity 1
    }
    setCart({ ...cart }); // Update the cart state
    localStorage.setItem('cart', JSON.stringify(cart)); // Store the cart in local storage
    window.dispatchEvent(new Event('cart')); // Trigger a cart update event
  };

  const removeFromCart = (userId, productId) => {
    if (cart[userId]) {
      const productIndex = cart[userId].findIndex((item) => item.productId === productId);
      if (productIndex !== -1) {
        if (cart[userId][productIndex].quantity > 1) {
          cart[userId][productIndex].quantity -= 1; // Decrease quantity if it's above 1
        } else {
          cart[userId].splice(productIndex, 1); // Remove the product from the cart if quantity is 1
        }
      }
    }
    setCart({ ...cart }); // Update the cart state
    localStorage.setItem('cart', JSON.stringify(cart)); // Store the cart in local storage
    window.dispatchEvent(new Event('cart')); // Trigger a cart update event
  };

  const getProductQuantityInCart = (userId, productId) => {
    if (cart[userId]) {
      const productInCart = cart[userId].find(item => item.productId === productId);
      return productInCart ? productInCart.quantity : 0; // Return the quantity of the product in the cart
    }
    return 0; // Return 0 if the user's cart doesn't exist or the product is not in the cart
  }
 
  return (
	<>
		<Helmet>
			{/* A conditional check is required since a shop is undefined before data is retrieved. */}
			<title>{user && user.username ? user.username + ' - ' : ''}{shop && shop.name ? shop.name : ''}</title>
			<meta name="description" content={shop && shop.description} />
		</Helmet>

		<section id="first-section">
			<div className="title-with-img">
				<Link to={`/${user_urlfriendlyname}`}>
					<button className="previous-button">
						<FontAwesomeIcon icon={faArrowLeft} />
					</button>
				</Link>
				<h1>{shop && shop.name}</h1>
			</div>
			<h2>{shop && shop.description}</h2>
		</section>

		<section id="second-section">
			<div className = "element-container">

		{message ? (
			<p>{message}</p> // Display loading/error message
		) : (
			products.map((product) => (
				<div className="element product" key={product.id}>
					<h3>{product.name}</h3>
					<div className='description'>{product.description}</div>
					{product.image ? (
					<img src={`${config.upload_url}/${product.image}`} alt={product.name} className="product-image" />
					) : (
					// Optional: A placeholder image or nothing
					<img src={`${config.upload_url}/missing.jpg`} alt="Placeholder" className="product-image" />
					)}

	                <div className='quantity'>{product.quantity} disponible(s)</div>
	                <div className='price'>{product.price} €</div>
	                <div className='cart-action'>
	                  <button onClick={() => removeFromCart(product.user_id, product.id)}>-</button>
	                  <div className='amount'>{getProductQuantityInCart(product.user_id, product.id)}</div>
	                  <button onClick={() => addToCart(product.user_id, product.id, product.quantity)}>+</button>
					</div>
				</div>
            ))
          )}
        </div>
    </section>
    </>
  );
}

export default ShopPage;
