import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserInfo, updateUserInfo } from '../../../api/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const UserForm = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: ""
  });
  const [errorMessages, setErrorMessages] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await getUserInfo();
      if (response.success) {
        const { first_name, last_name, email } = response.user;
        setUserData({ first_name, last_name, email, password: '' });
        setLoading(false);
      } else {
        setErrorMessages(response.messages);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await updateUserInfo(userData);
    if (response.success) {
      toast.success('User information updated successfully!', { autoClose: 3000 });
    } else {
      setErrorMessages(response.messages);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
   <div className = "element-container">
	<h3>Informations</h3>
    <form onSubmit={handleSubmit} className='element'>
      <div className={`field ${errorMessages.first_name ? 'error-field' : ''}`}>
        <label htmlFor="first_name">First Name</label>
        <input
          id="first_name"
          value={userData.first_name}
          onChange={handleChange}
          className={errorMessages.first_name ? 'error-input' : ''}
        />
        {errorMessages.first_name && (
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="error-icon"
          />
        )}
        {errorMessages.first_name && (
          <div className="error">{errorMessages.first_name}</div>
        )}
      </div>

      <div className={`field ${errorMessages.last_name ? 'error-field' : ''}`}>
        <label htmlFor="last_name">Last Name</label>
        <input
          id="last_name"
          value={userData.last_name}
          onChange={handleChange}
          className={errorMessages.last_name ? 'error-input' : ''}
        />
        {errorMessages.last_name && (
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="error-icon"
          />
        )}
        {errorMessages.last_name && (
          <div className="error">{errorMessages.last_name}</div>
        )}
      </div>

      <div className={`field ${errorMessages.email ? 'error-field' : ''}`}>
        <label htmlFor="email">Email</label>
        <input
          id="email"
          value={userData.email}
          onChange={handleChange}
          className={errorMessages.email ? 'error-input' : ''}
        />
        {errorMessages.email && (
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="error-icon"
          />
        )}
        {errorMessages.email && (
          <div className="error">{errorMessages.email}</div>
        )}
      </div>

      <div className={`field ${errorMessages.password ? 'error-field' : ''}`}>
        <label htmlFor="password">Password</label>
        <input
          id="password"
          type="password"
          value={userData.password}
          onChange={handleChange}
          className={errorMessages.password ? 'error-input' : ''}
        />
        {errorMessages.password && (
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="error-icon"
          />
        )}
        {errorMessages.password && (
          <div className="error">{errorMessages.password}</div>
        )}
      </div>

      <button type="submit">Valider</button>
    </form>
    </div>
  );
};

export default UserForm;
