export const ROUTE_HOME = "/";
export const ROUTE_LOGIN = "/login";
export const ROUTE_ADMIN = "/admin";
export const ROUTE_ADMIN_STEP_1 = "/admin/step-1";
export const ROUTE_ADMIN_STEP_2 = "/admin/step-2";
export const ROUTE_ADMIN_STEP_3 = "/admin/step-3";
export const ROUTE_ADMIN_PRODUCTS = "/admin/products";
export const ROUTE_ADMIN_PRODUCTS_ADD = "/admin/products/add";
export const ROUTE_ADMIN_ORDERS = "/admin/orders";
export const ROUTE_ADMIN_PROFIL = "/admin/profil";
export const ROUTE_ADMIN_PROFIL_SHOPADD = "/admin/profil/shop-add";
export const ROUTE_URLFRIENDLY = "/:user_urlfriendlyname";
export const ROUTE_URLFRIENDLY_SHOP = "/:user_urlfriendlyname/:shop_urlfriendlyname";
export const ROUTE_CART = "/cart";