import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import checkAuthentication from './checkAuthentication';
import AuthContext from './AuthContext';
import { ROUTE_LOGIN } from './routes';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext); // Access the isAuthenticated and setIsAuthenticated values from the AuthContext
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  useEffect(() => {
    const checkAuth = async () => {
      const authStatus = await checkAuthentication(); // Perform an authentication check
      setIsAuthenticated(authStatus); // Update the isAuthenticated value based on the authentication check
      setIsLoading(false); // Set loading state to false after checking authentication
    };
    checkAuth(); // Invoke the authentication check function
  }, [setIsAuthenticated]); // Add setIsAuthenticated as a dependency for the useEffect hook

  // Loading is required to solve race condition between the render of your ProtectedRoute component and the async checkAuthentication function.
  // Without it, the user is redirected to the login page despite having a valid auth_token
  if (isLoading) {
    return <div>Chargement...</div>; // Show a loading message while authentication is being checked
  } else if (!isAuthenticated) {
    return (
      <Navigate
        to={ROUTE_LOGIN}
        state={{ from: location }} // Pass the current location as state to the login page, so the user can be redirected back after authentication
      />
    );
  } else {
    return children; // Render the protected route's children if authenticated
  }
};

export default ProtectedRoute;
