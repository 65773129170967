import { apiCall } from './apiHelper';

// Private routes
export const logOut = apiCall('post', '/api/v1/user/logOut', true);
export const getUserInfo = apiCall('get', '/api/v1/user', true);
//export const updateUserInfo = apiCall('put', '/api/v1/user/update', true);
export const updateUserInfo = (data) => apiCall('put', `/api/v1/user/update`, true)(data);

// Public routes
export const signUp = apiCall('post', '/api/v1/user/signUp', false);
export const logIn = apiCall('post', '/api/v1/user/logIn', false);
export const getPublicUserInfoByUrlFriendlyName = (urlfriendlyname) => apiCall('get', `/api/v1/user/${urlfriendlyname}`, false);