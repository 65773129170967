import React from 'react';
import ProfilShopsAdd from '../profil/ProfilShopsAdd';
import { ROUTE_ADMIN_STEP_2 } from '../../../utils/routes';
import { Helmet } from 'react-helmet';

const Step1 = () => {
	return (
		<>
			<Helmet>
				<title>Étape 1/2</title>
	      </Helmet>
				<section id="first-section">
					<h1>Créez votre boutique</h1>
					<h2>C'est parti !</h2>
		
			</section>
			<section id="second-section">
				<div className = "element-container">
						<ProfilShopsAdd navigateTo={ROUTE_ADMIN_STEP_2} />
				</div>
			</section>
		</>
	);
};

export default Step1;
