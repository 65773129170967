import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getProductInfo, updateProductInfo, deleteProductInfo } from '../../../api/product';
import { getShopInfo } from '../../../api/shop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { config } from '../../../config'; // Required to retrieve config.upload_url URL

const ProductsForm = () => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [images, setImages] = useState({});
  const [shopOptions, setShopOptions] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await getProductInfo();
      const shopResponse = await getShopInfo();

      if (response.success) {
        setProducts(response.products);
        const shopOpts = shopResponse.shops.map((shop) => ({
          id: shop.id,
          name: shop.name,
        }));
        setShopOptions(shopOpts);
      } else {
        setErrorMessages(response.messages);
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  const handleChange = (e, id, field) => {
    const updatedProducts = products.map((product) => {
      if (product.id === id) {
        return { ...product, [field]: e.target.value };
      }
      return product;
    });
    setProducts(updatedProducts);
  };

  const handleImageChange = (e, id) => {
    const file = e.target.files[0];
    if (file) {
      setImages(prevImages => ({
        ...prevImages,
        [id]: file
      }));
    }
  };

  const handleSubmit = async (e, id) => {
    e.preventDefault();
    const productToUpdate = products.find((product) => product.id === id);

    const formData = new FormData();
    for (const key in productToUpdate) {
      formData.append(key, productToUpdate[key]);
    }
    if (images[id]) {
      formData.append('image', images[id]);
    }

    try {
      const response = await updateProductInfo(id, formData); 
			      
		if (response.success) {
		    toast.success('Votre produit a été mis à jour !', { autoClose: 3000 });
		    setErrorMessages((prevErrors) => {
		        const updatedErrors = { ...prevErrors };
		        delete updatedErrors[id];
		        return updatedErrors;
		    });
		
		    // Update the image for the product in state
		    setProducts((prevProducts) => {
		        return prevProducts.map((product) => {
		            if (product.id === id) {
		                return {
		                    ...product,
		                    image: response.newImageFileName // This is assuming your backend returns the new filename as `newImageFileName`
		                };
		            }
		            return product;
		        });
		    });
		
		} else if (response.messages) {
		    setErrorMessages({ ...errorMessages, [id]: response.messages });
		}
      
      
    } catch (error) {
      console.error('Error updating product info:', error);
      setErrorMessages({ ...errorMessages, [id]: 'Error updating product information.' });
    }
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    try {
      const response = await deleteProductInfo(id);
      if (response.success) {
        toast.success('Votre produit a été supprimé !', { autoClose: 3000 });
        setProducts(products.filter((product) => product.id !== id));
      } else {
        setErrorMessages({ ...errorMessages, [id]: response.errors });
      }
    } catch (error) {
      console.error('Error deleting product:', error);
      setErrorMessages({ ...errorMessages, [id]: 'Error deleting product.' });
    }
  };

  return (
    <div className = "element-container">
      {loading && <p>Loading...</p>}
      {!loading && products.map((product) => (
      	
          <form onSubmit={(e) => handleSubmit(e, product.id)} className="element">
            <div className='icon-remove' type="button" onClick={(e) => handleDelete(e, product.id)}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </div>

            <div className={`field ${errorMessages[product.id]?.shop ? 'error-field' : ''}`}>
              <label htmlFor={`shop_id_${product.id}`}>Catégorie</label>
              <div className="input-icon-wrapper">
                <select
                  id={`shop_id_${product.id}`}
                  name={`shop_id_${product.id}`}
                  value={product.shop_id}
                  onChange={(e) => handleChange(e, product.id, 'shop_id')}
                  className={errorMessages[product.id]?.shop ? 'error-input' : ''}
                >
                  {shopOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
                {errorMessages[product.id]?.shop && <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />}
              </div>
              {errorMessages[product.id]?.shop && (
                <div className="error">{errorMessages[product.id].shop}</div>
              )}
            </div>
            
            <div className={`field ${errorMessages[product.id]?.name ? 'error-field' : ''}`}>
              <label htmlFor={`name_${product.id}`}>Nom du produit</label>
              <div className="input-icon-wrapper">
                <input
                  id={`name_${product.id}`}
                  name={`name_${product.id}`}
                  type="text"
                  value={product.name}
                  onChange={(e) => handleChange(e, product.id, 'name')}
                  className={errorMessages[product.id]?.name ? 'error-input' : ''}
                />
                {errorMessages[product.id]?.name && <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />}
              </div>
              {errorMessages[product.id]?.name && (
                <div className="error">{errorMessages[product.id].name}</div>
              )}
            </div>
            <div className={`field ${errorMessages[product.id]?.description ? 'error-field' : ''}`}>
              <label htmlFor={`description_${product.id}`}>Description</label>
              <div className="input-icon-wrapper">
                <input
                  id={`description_${product.id}`}
                  name={`description_${product.id}`}
                  type="text"
                  value={product.description}
                  onChange={(e) => handleChange(e, product.id, 'description')}
                  className={errorMessages[product.id]?.description ? 'error-input' : ''}
                />
                {errorMessages[product.id]?.description && <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />}
              </div>
              {errorMessages[product.id]?.description && (
                <div className="error">{errorMessages[product.id].description}</div>
              )}
            </div>
            <div className={`field ${errorMessages[product.id]?.price ? 'error-field' : ''}`}>
              <label htmlFor={`price_${product.id}`}>Prix</label>
              <div className="input-icon-wrapper">
                <input
                  id={`price_${product.id}`}
                  name={`price_${product.id}`}
                  type="number"
                  value={product.price}
                  onChange={(e) => handleChange(e, product.id, 'price')}
                  className={errorMessages[product.id]?.price ? 'error-input' : ''}
                />
                {errorMessages[product.id]?.price && <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />}
              </div>
              {errorMessages[product.id]?.price && (
                <div className="error">{errorMessages[product.id].price}</div>
              )}
            </div>
            <div className={`field ${errorMessages[product.id]?.quantity ? 'error-field' : ''}`}>
              <label htmlFor={`quantity_${product.id}`}>Quantité</label>
              <div className="input-icon-wrapper">
                <input
                  id={`quantity_${product.id}`}
                  name={`quantity_${product.id}`}
                  type="number"
                  value={product.quantity}
                  onChange={(e) => handleChange(e, product.id, 'quantity')}
                  className={errorMessages[product.id]?.quantity ? 'error-input' : ''}
                />
                {errorMessages[product.id]?.quantity && <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />}
              </div>
              {errorMessages[product.id]?.quantity && (
                <div className="error">{errorMessages[product.id].quantity}</div>
              )}
            </div>
            
{/* Display product image if it exists */}
<label htmlFor={`image_${product.id}`} className="image-label">
  {product.image ? (
    <img 
      src={`${config.upload_url}/${product.image}`} 
      alt={`Image of ${product.name}`} 
      className="product-image"
    />
  ) : (
    <img
      src={`${config.upload_url}/missing.jpg`}
      alt="Image Missing"
      className="product-image"
    />
  )}
  <input 
    type="file"
    id={`image_${product.id}`}
    name={`image_${product.id}`}
    onChange={(e) => handleImageChange(e, product.id)}
    className="hidden-input"
  />
</label>
            
            <div className={`field ${errorMessages.image ? 'error-field' : ''}`}>
  
            </div>
            
            <button type="submit">Valider</button>
          </form>
		
      ))}
    </div>
  );
};

export default ProductsForm;