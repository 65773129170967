import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getShopInfo, updateShopInfo, createShop } from '../../../api/shop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const ShopsForm = () => {
  const [loading, setLoading] = useState(true);
  const [shops, setShops] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getShopInfo();
        if (response.success) {
          setShops(response.shops);
        } else {
          setErrorMessages(response.messages);
        }
      } catch (error) {
        console.error('Error fetching shop info:', error);
        setErrorMessages({ message: 'Error fetching shop information.' });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e, id, field) => {
    const updatedShops = shops.map(shop => {
      if (shop.id === id) {
        return { ...shop, [field]: e.target.value };
      }
      return shop;
    });
    setShops(updatedShops);
  };

  const handleSubmit = async (e, id) => {
    e.preventDefault();
    const shopToUpdate = shops.find((shop) => shop.id === id);
    try {
      const response = await updateShopInfo(id, shopToUpdate);
      if (response.success) {
        toast.success('Shop information updated successfully!', { autoClose: 3000 });
      } else {
        setErrorMessages({ ...errorMessages, [id]: response.messages });
      }
    } catch (error) {
      console.error('Error updating shop info:', error);
      setErrorMessages({ ...errorMessages, [id]: 'Error updating shop information.' });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (shops.length === 0) {
    return null;
  }

  return (

    <div className = "element-container">
	<h3>Boutique{shops.length > 1 ? 's' : ''}</h3>
      {shops.map((shop) => (
        <form key={shop.id} onSubmit={(e) => handleSubmit(e, shop.id)} className='element'>
        
          <div className={`field ${errorMessages[shop.id] ? 'error-field' : ''}`}>
            <label htmlFor="name">Nom de la boutique</label>
            <input
              id="name"
              value={shop.name}
              onChange={(e) => handleChange(e, shop.id, 'name')}
              className={errorMessages[shop.id] ? 'error-input' : ''}
            />
            {errorMessages[shop.id] && (
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className="error-icon"
              />
            )}
            {errorMessages[shop.id] && (
              <div className="error">{errorMessages[shop.id]}</div>
            )}
          </div>

          <div className={`field ${errorMessages[shop.id] ? 'error-field' : ''}`}>
            <label htmlFor="description">Description</label>
            <input
              id="description"
              value={shop.description}
              onChange={(e) => handleChange(e, shop.id, 'description')}
              className={errorMessages[shop.id] ? 'error-input' : ''}
            />
            {errorMessages[shop.id] && (
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className="error-icon"
              />
            )}
            {errorMessages[shop.id] && (
              <div className="error">{errorMessages[shop.id]}</div>
            )}
          </div>

          {/* Repeat above for all other shop fields as per your data structure */}

          <button type="submit">Valider</button>
        </form>
      ))}
    </div>
  );
};

export default ShopsForm;
