import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createProduct } from '../../../api/product';
import { getShopInfo } from '../../../api/shop';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';

const NewProductForm = ({ onSubmit, redirectAfterSubmit }) => {
  const [product, setProduct] = useState({
    shop_id: '',
    name: '',
    description: '',
    price: 0,
    quantity: 1
  });
  const [image, setImage] = useState(null);  // New state for the image
  const [errorMessages, setErrorMessages] = useState({});
  const [shopOptions, setShopOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchShopOptions();
  }, []);

  const fetchShopOptions = async () => {
    const response = await getShopInfo();
    if (response.success) {
      const shopOptions = response.shops.map((shop) => ({
        id: shop.id,
        name: shop.name,
      }));
      setShopOptions(shopOptions);
    }
  };

  const handleChange = (event) => {
    setProduct({
      ...product,
      [event.target.name]: event.target.value
    });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    for (const key in product) {
      formData.append(key, product[key]);
    }
    if (image) {
      formData.append('image', image);
    }

    const response = await createProduct(formData);  // Modify the API call to use FormData

    if (response.success) {
      navigate(redirectAfterSubmit);
      toast.success(response.message, { autoClose: 3000 });
      onSubmit();
    } else if (response.messages) {
      setErrorMessages(response.messages);
    }
  };

  const renderField = (name, label, type = 'text', options = null) => (
    <div className={`field ${errorMessages[name] ? 'error-field' : ''}`}>
      <label htmlFor={name}>{label}</label>
      {options ? (
        <select name={name} id={name} value={product[name]} onChange={handleChange}>
          <option value="">Sélectionnez une catégorie</option>
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      ) : (
        <input id={name} name={name} type={type} value={product[name]} onChange={handleChange} placeholder={label} />
      )}
      {errorMessages[name] && (
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="error-icon"
        />
      )}
      {errorMessages[name] && (
        <div className="error">{errorMessages[name]}</div>
      )}
    </div>
  );

  return (
	<div className = "element-container">
	    <form onSubmit={handleSubmit} className='element'>
	      {renderField('shop_id', 'Catégorie', 'select', shopOptions)}
	      {renderField('name', 'Nom du produit')}
	      {renderField('description', 'Description')}
	      {renderField('price', 'Prix', 'number')}
	      {renderField('quantity', 'Quantité', 'number')}
	      <div className={`field ${errorMessages.image ? 'error-field' : ''}`}>   {/* Image upload field */}
	        <label htmlFor="image">Image du produit</label>
	        <input 
	          type="file"
	          id="image"
	          name="image"
	          onChange={handleImageChange}
	        />
	      </div>
	      <button type="submit">Ajouter ce produit</button>
	    </form>
	</div>
  );
};

export default NewProductForm;