import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createShop } from '../../../api/shop';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

function Step1FormAddShop({ navigateTo }) {
  const [shopData, setShopData] = useState({
    name: "",
    description: "",
  });
  const [errorMessages, setErrorMessages] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    setShopData({ ...shopData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createShop(shopData);
      if (response.success) {
        toast.success(response.message, { autoClose: 3000 });
        navigate(navigateTo);
      } else {
        setErrorMessages(response.messages);
      }
    } catch (err) {
      alert('An error has occurred');
    }
  };

  return (
	<div className = "element-container">
      <form onSubmit={handleSubmit} className="element">
        <div className={`field ${errorMessages.name ? 'error-field' : ''}`}>
          <label htmlFor="name">Nom de votre boutique</label>
          <input
            type="text"
            id="name"
            value={shopData.name}
            onChange={handleChange}
            placeholder="Nom de votre boutique"
            className={errorMessages.name ? 'error-input' : ''}
          />
          {errorMessages.name && (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="error-icon"
            />
          )}
          {errorMessages.name && (
            <div className="error">{errorMessages.name}</div>
          )}
        </div>
        <div className={`field ${errorMessages.description ? 'error-field' : ''}`}>
          <label htmlFor="description">Description</label>
          <input
            type="text"
            id="description"
            value={shopData.description}
            onChange={handleChange}
            placeholder="Description"
            className={errorMessages.description ? 'error-input' : ''}
          />
          {errorMessages.description && (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="error-icon"
            />
          )}
          {errorMessages.description && (
            <div className="error">{errorMessages.description}</div>
          )}
        </div>
        <button type="submit">Créer cette boutique</button>
      </form>
    </div>
  );
}

export default Step1FormAddShop;
