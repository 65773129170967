import React from 'react';
import SignUp from '../../core/SignUp';
import { Helmet } from 'react-helmet';

// Import the logo
import logo from '../../../assets/img/logo.png';

const HomePage = () => {
  return (
	<>
		<Helmet>
			<title>ClickBoutick</title>
			<meta name="description" content="Bienvenue sur Clickboutick !" />
		</Helmet>
		<section id="first-section">
			<div className="title-with-img"> 
				<img src={logo} alt="Clickboutick Logo" />
				<h1>Clickboutick</h1>
			</div>
			<h2>Créez gratuitement votre boutique.</h2>
		</section>
		<section id="second-section">
			<SignUp />
		</section>
	</>
  );
};

export default HomePage;
