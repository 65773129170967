import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as ROUTES from '../../../utils/routes';

// Import the logo
import logo from '../../../assets/img/logo.png';

const AuthenticatedHome = () => {
  return (
	<>
		<Helmet>
			<title>Vous êtes déjà connecté</title>
			<meta name="description" content="Bienvenue sur Clickboutick !" />
		</Helmet>
		<section id="first-section">
			<div className="title-with-img"> 
				<img src={logo} alt="Clickboutick Logo" />
				<h1>Clickboutick</h1>
			</div>
			<h2>Votre boutique en ligne.</h2>
		</section>
		<section id="second-section">
			<div className = "element-container">
			<h3>Vous êtes connecté.</h3>
				<div className="element menu">
					<Link to={ROUTES.ROUTE_ADMIN}>Accéder à mon compte</Link>
				</div>
			</div>
		</section>
	</>
  );
};

export default AuthenticatedHome;
