import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteCookie } from '../../utils/cookie';
import { logOut } from '../../api/user';
import { toast } from 'react-toastify';
import AuthContext from '../../utils/AuthContext'; // import the context

const Logout = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext); // use the context


const handleLogout = async () => {
  const result = await logOut({});
  if (result.success) {
    deleteCookie('auth_token');
    setIsAuthenticated(false);
    navigate('/');
    toast.success('Vous êtes déconnecté.', { autoClose: 3000 });
  } else {
    // Handle error
    console.error('Logout failed', result.messages);
  }
};

  return (
    <button onClick={handleLogout}>Se déconnecter</button>
  );
};

export default Logout;