import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { setCookie } from '../../utils/cookie';
import { logIn } from '../../api/user';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { ROUTE_ADMIN, ROUTE_HOME } from '../../utils/routes';

function LogIn() {
  const [userData, setUserData] = useState({ email: '', password: '' });
  const [errorMessages, setErrorMessages] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.id]: e.target.value });
  };

   const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await logIn(userData);
      if (response.success) {
        setCookie('auth_token', response.token);
        toast.success(response.message, { autoClose: 3000 });
        navigate(ROUTE_ADMIN);
      } else {
        setErrorMessages(response.messages);
      }
    } catch (err) {
      alert('Une erreur s\'est produite');
    }
  };

  return (
    <div className = "element-container">
      <h3>Connexion</h3>
      <form onSubmit={handleSubmit} className="element">
        <div className={`field ${errorMessages.email ? 'error-field' : ''}`}>
          <label htmlFor="email">Email</label>
          <input
            id="email"
            value={userData.email}
            onChange={handleChange}
            placeholder="Adresse email de votre compte"
            className={errorMessages.email ? 'error-input' : ''}
          />
          {errorMessages.email && (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="error-icon"
            />
          )}
          {errorMessages.email && (
            <div className="error">{errorMessages.email}</div>
          )}
        </div>
        <div className={`field ${errorMessages.password ? 'error-field' : ''}`}>
          <label htmlFor="password">Mot de passe</label>
          <input
            type="password"
            id="password"
            value={userData.password}
            onChange={handleChange}
            placeholder="Mot de passe associé"
            className={errorMessages.password ? 'error-input' : ''}
          />
          {errorMessages.password && (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="error-icon"
            />
          )}
          {errorMessages.password && (
            <div className="error">{errorMessages.password}</div>
          )}
        </div>
        {errorMessages.general && <div className="error">{errorMessages.general}</div>}
        <button type="submit">Se connecter</button>
      </form>
      <div className="subform-link">
        <Link to={ROUTE_HOME}>Je souhaite créer un compte.</Link>
      </div>
    </div>
  );
}

export default LogIn;
