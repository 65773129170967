import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CartContext } from '../../components/CartContext';
import { getUserInfo } from '../../api/user';
import * as ROUTES from '../../utils/routes';
import AuthContext from '../../utils/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/img/logo_header.png'; // Import your logo

const Header = () => {
	const location = useLocation();
	const { isAuthenticated } = useContext(AuthContext);
	const [cart, setCart] = useContext(CartContext);
	const [authenticatedUserUrlFriendlyName, setAuthenticatedUserUrlFriendlyName] = useState(null);
	const [headerTitle, setHeaderTitle] = useState('ClickBoutick'); // For default/unauthenticated users
	const [headerPreviousButton, setheaderPreviousButton] = useState(0);  // 0 means don't display, 1 means display
	const [headerPreviousLink, setHeaderPreviousLink] = useState(0);  // 0 means don't display, 1 means display

	useEffect(() => {
		const paramUserUrlFriendlyName = location.pathname.split('/').filter(Boolean)[0];

		if (isAuthenticated) {
	    const fetchUserInfo = async () => {
	      const response = await getUserInfo();
	      if (response && response.success) {
	        setAuthenticatedUserUrlFriendlyName(response.user.username_urlfriendly);
	        const paramUserUrlFriendlyName = location.pathname.split('/').filter(Boolean)[0];
	        if (paramUserUrlFriendlyName === authenticatedUserUrlFriendlyName) {
	            setHeaderTitle("Ma boutique");
	            setheaderPreviousButton(1);
				setHeaderPreviousLink(ROUTES.ROUTE_ADMIN);
	        }
	        else {

			  if (location.pathname === ROUTES.ROUTE_ADMIN) {
			    setHeaderTitle("Accueil");
			    setheaderPreviousButton(0);

			  } else if (location.pathname === ROUTES.ROUTE_ADMIN_PRODUCTS) {
			    setHeaderTitle("Produits");
			    setheaderPreviousButton(1);
				setHeaderPreviousLink(ROUTES.ROUTE_ADMIN);
			  } else if (location.pathname === ROUTES.ROUTE_ADMIN_PRODUCTS_ADD) {
			    setHeaderTitle("Ajouter un produit");
			    setheaderPreviousButton(1);
				setHeaderPreviousLink(ROUTES.ROUTE_ADMIN_PRODUCTS);
			  } else if (location.pathname === ROUTES.ROUTE_ADMIN_ORDERS) {
			    setHeaderTitle("Commandes");
			    setheaderPreviousButton(1);
				setHeaderPreviousLink(ROUTES.ROUTE_ADMIN);
			  } else if (location.pathname === ROUTES.ROUTE_ADMIN_PROFIL) {
			    setHeaderTitle("Profil");
			    setheaderPreviousButton(1);
				setHeaderPreviousLink(ROUTES.ROUTE_ADMIN);
			  } else if (location.pathname === ROUTES.ROUTE_ADMIN_PROFIL_SHOPADD) {
			    setHeaderTitle("Ajouter une boutique");
			    setheaderPreviousButton(1);
				setHeaderPreviousLink(ROUTES.ROUTE_ADMIN_PROFIL);
			  } else {
			    setHeaderTitle("Clickboutick");
			  }
	        }
	      }
	    };
	    fetchUserInfo();
	  }
 
	// Debugging
	// console.log('Current pathname:', location.pathname);
	// console.log('paramUserUrlFriendlyName:', paramUserUrlFriendlyName);
	// console.log('setAuthenticatedUserUrlFriendlyName:', authenticatedUserUrlFriendlyName);

}, [isAuthenticated, location.pathname, authenticatedUserUrlFriendlyName]);

  // Calculate total number of items in the cart
  const cartItemCount = Object.values(cart).reduce((acc, userCart) => {
    return acc + userCart.reduce((acc, item) => acc + item.quantity, 0);
  }, 0);

  // No header in these cases
  if (location.pathname === ROUTES.ROUTE_HOME || location.pathname === ROUTES.ROUTE_LOGIN) {
    return null;
  }

  return (
    <header>
      <div id='content'>
    	   <div id='left-area'>
        {headerPreviousButton === 1 && (
		<Link to={headerPreviousLink}>
		  <button className="previous-button">
		    <FontAwesomeIcon icon={faArrowLeft} />
		  </button>
		</Link>
        )}
        <div id='title'>
          <img src={logo} alt="Logo" /> {/* Include your logo */}
          <div>{headerTitle}</div>
        </div>
       </div>
           	   <div id='right-area'>
{isAuthenticated ? (
  <Link to={ROUTES.ROUTE_ADMIN}>
    <button>Mon compte</button>
  </Link>
) : (
  <Link to={ROUTES.ROUTE_HOME}>
    <button>Créer une boutique</button>
  </Link>
)}
        <Link id = "cart" to={ROUTES.ROUTE_CART}>
          <button>&#128722;{cartItemCount}</button>
        </Link>
        </div>
      </div>
    </header>
  );
};


export default Header;
