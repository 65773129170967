import React from 'react';
import { createRoot } from 'react-dom/client';
import './assets/css/layout.css';
import './assets/css/elements.css';
import './assets/css/forms.css';
import App from './App';

const root = document.getElementById('root');

// Use createRoot method for React 18
createRoot(root).render(<App />);
