export const setCookie = (name, value, options = {}) => {
  options = {
    path: '/', // Set the default path for the cookie to '/'
  };

  // Convert the 'expires' option to a UTC string if it is a Date object
  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  // Construct the cookie string with the provided name and value
  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  // Append options to the cookie string
  for (let optionKey in options) {
    updatedCookie += '; ' + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  // Set the updated cookie by assigning it to the document's 'cookie' property
  document.cookie = updatedCookie;
};

export const getCookie = (name) => {
  // Find the cookie with the given name using a regular expression
  const matches = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (matches) {
    // Decode and return the value of the matching cookie
    return decodeURIComponent(matches[2]);
  }
};

export const deleteCookie = (name) => {
  // Call the setCookie function with a negative 'max-age' value to delete the cookie
  setCookie(name, '', { 'max-age': -1 });
};
