import React, { createContext, useState, useEffect } from "react";

// Creating a new context for Cart
export const CartContext = createContext();

// This component provides the cart state to its children
export const CartProvider = ({ children }) => {
  // Creating state for cart and a function to update it
  const [cart, setCart] = useState({});

  // This effect runs once after the component mounts (due to the empty dependency array)
  useEffect(() => {
    // Attempting to retrieve the cart from local storage
    const cartFromStorage = localStorage.getItem("cart");
    // If we find a cart in local storage, we parse it from JSON and update the cart state
    if (cartFromStorage) {
      setCart(JSON.parse(cartFromStorage));
    }
  }, []); // The empty array means this effect will only run once, after initial render

  // This effect runs whenever the cart state changes
  useEffect(() => {
    // Updating the cart in local storage to match the current cart state
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]); // Adding cart as a dependency means this effect will run whenever cart changes

  // We provide the cart state and the setCart function to children of this component
  return (
    <CartContext.Provider value={[cart, setCart]}>
      {children}
    </CartContext.Provider>
  );
};