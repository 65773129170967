import React, { useState } from 'react';
import { createOrder } from '../../../api/order';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

// Create the CartPage functional component
function CartPage() {
  // define cart state using useState hook and set it to cart data from local storage, if available, otherwise an empty object
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || {});

  // define function to add an item to the cart
  const addToCart = (userId, productId) => {
    // if user already has items in the cart
    if (cart[userId]) {
      // find the index of the product in the user's cart
      const productIndex = cart[userId].findIndex((item) => item.productId === productId);
      // if the product is found in the cart, increment its quantity
      if (productIndex !== -1) {
        cart[userId][productIndex].quantity += 1;
      } 
    }
    // update the cart state and save the updated cart to local storage
    setCart({ ...cart });
    localStorage.setItem('cart', JSON.stringify(cart));
  };

  // define function to remove an item from the cart
  const removeFromCart = (userId, productId) => {
    // if user already has items in the cart
    if (cart[userId]) {
      // find the index of the product in the user's cart
      const productIndex = cart[userId].findIndex((item) => item.productId === productId);
      // if the product is found in the cart
      if (productIndex !== -1) {
        // if the quantity of the product is more than one, decrement its quantity
        if (cart[userId][productIndex].quantity > 1) {
          cart[userId][productIndex].quantity -= 1;
        } else {
          // else remove the product from the cart
          cart[userId].splice(productIndex, 1);
        }
      }
    }
    // update the cart state and save the updated cart to local storage
    setCart({ ...cart });
    localStorage.setItem('cart', JSON.stringify(cart));
  };

  // define function to validate and create order
  const handleValidateCart = () => {
    // if the cart is empty, show error notification and return
    if (Object.keys(cart).length === 0) {
      toast.error('The cart is empty');
      return;
    }

    // call API to create order
    createOrder(cart)
      .then(response => {
        // if order creation is successful, show success notification, clear the cart state and local storage
        if (response.success) {
          toast.success(response.message);
          setCart({});
          localStorage.setItem('cart', JSON.stringify({}));
        }
      })
      // if order creation fails, show error notification
      .catch(() => {
        toast.error('An error occurred while creating the order');
      });
  };

  // render the CartPage component
  return (
      <>
		<Helmet>
			<title>Mon panier</title>
			<meta name="description" content="Liste des produits de votre panier." />
		</Helmet>
      
		<section id="first-section">
			<h1>Votre panier</h1> {/* Title of the page */}
			<Link to=""><button onClick={handleValidateCart}>Valider votre panier</button></Link> {/* Link to validate the cart */}
        </section>

		<section id="second-section">
			<div className = "element-container">
          {/* map over each user's cart items */}
          {Object.keys(cart).map((userId) => (
            <div key={userId}>
              {/* map over each item in the user's cart */}
              {cart[userId].map((item) => (
                <div className="element" key={item.productId}>
                  <h2>User ID: {userId}</h2> {/* Display user id */}
                  <h3>Product ID: {item.productId}</h3> {/* Display product id */}
                  <div className = 'cart-action'>
                    {/* Button to decrease the quantity of the item */}
                    <button onClick={() => removeFromCart(userId, item.productId)}>-</button>
                    {/* Display the quantity of the item */}
                    <div className = 'amount'>{item.quantity}</div>
                    {/* Button to increase the quantity of the item */}
                    <button onClick={() => addToCart(userId, item.productId)}>+</button>
                  </div>
                </div>
              ))}
            </div>
          ))}
        	</div>
        </section>
      </>
  );
}

// export the CartPage component as default
export default CartPage;
