import React from 'react';
import ProductsAdd from './ProductsAdd'; // Import the ProductsAdd component
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ROUTE_ADMIN_PRODUCTS } from '../../../utils/routes';
import { Helmet } from 'react-helmet';

const Products = () => {
    return (
      <>
		<Helmet>
			<title>Ajouter un produit</title>
		</Helmet>
		<section id="first-section">
			<h1>Ajouter un produit</h1>
			<h2>Indiquez ses caractéristiques.</h2>
        </section>
		<section id="second-section">
			<ProductsAdd redirectAfterSubmit={ROUTE_ADMIN_PRODUCTS} />
		</section>
      </>
    );
};

export default Products;
