import React from 'react';
import ProfilShopsAdd from './ProfilShopsAdd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ROUTE_ADMIN_PROFIL } from '../../../utils/routes';
import { Helmet } from 'react-helmet';

const ShopAdd = () => {
    return (
      <>
		<Helmet>
			<title>Ajouter une boutique</title>
		</Helmet>
		<section id="first-section">
			<h1>Ajouter une boutique</h1>
			<h2>Indiquez ses caractéristiques.</h2>
		</section>
		<section id="second-section">
            <ProfilShopsAdd navigateTo={ROUTE_ADMIN_PROFIL} />
        </section>
      </>
    );
};

export default ShopAdd;
