import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ROUTE_ADMIN } from '../../../utils/routes';
import OrdersList from './OrdersList';
import { Helmet } from 'react-helmet';

const Orders = () => {
    return (
      <>
		<Helmet>
			<title>Mes commandes</title>
		</Helmet>
		<section id="first-section">
			<h1>Commandes</h1>
            <h2>Liste de vos commandes.</h2>
		</section>
		<section id="second-section">
          <OrdersList />
        </section>
      </>
    );
};

export default Orders;
