import React from 'react';
import LogIn from '../../core/LogIn';
import { Helmet } from 'react-helmet';

// Import the logo
import logo from '../../../assets/img/logo.png';

const HomePage = () => {
  return (
	<>
		<Helmet>
			<title>Connexion</title>
			<meta name="description" content="Page de connexion." />
		</Helmet>
		<section id="first-section">
			<div className="title-with-img"> 
				<img src={logo} alt="Clickboutick Logo" />
				<h1>Clickboutick</h1>
			</div>
			<h2>Accédez à votre boutique.</h2>
		</section>
		<section id="second-section">
			<LogIn />
		</section>
	</>
  );
};

export default HomePage;