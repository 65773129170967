import React, { useState, useEffect, useContext } from 'react';
import AuthContext from './utils/AuthContext';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import * as ROUTES from './utils/routes';
import Header from './components/core/Header';
import Footer from './components/core/Footer';
import Home from './components/public/home/HomePage';
import AuthenticatedHome from './components/public/home/AuthenticatedHome';
import LogIn from './components/public/login/LoginPage';
import AdminMenu from './components/admin/MenuPage';
import AdminProducts from './components/admin/products/ProductsPage';
import AdminProductsAdd from './components/admin/products/ProductsPageAdd';
import AdminOrders from './components/admin/orders/OrdersPage';
import AdminProfil from './components/admin/profil/ProfilPage';
import AdminProfilShopAdd from './components/admin/profil/ProfilPageShopAdd';
import AdminStep1 from './components/admin/steps/Step1Page';
import AdminStep2 from './components/admin/steps/Step2Page';
import AdminStep3 from './components/admin/steps/Step3Page';
import User from './components/public/shop/UserPage';
import Shop from './components/public/shop/ShopPage';
import Cart from './components/public/shop/CartPage';
import { CartProvider } from './components/CartContext';
import ProtectedRoute from './utils/ProtectedRoute';
import checkAuthentication from './utils/checkAuthentication';

function App() {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isLoading, setIsLoading] = useState(true); // Introduce a "loading" state while the application is checking for authentication, in order to avoid "flash of unauthenticated content" (FOUC).

  useEffect(() => {
    const verifyAuth = async () => {
      const isAuth = await checkAuthentication();
      setIsAuthenticated(isAuth);
      setIsLoading(false); // Set loading to false once check is complete
    };

    verifyAuth();
  }, []);

  if (isLoading) {
    return <div></div>; // Show a loading spinner or placeholder
  }

  return (
    <CartProvider>
        <Router>
          <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
            <div className="App">
              <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
              <Header />
              <main>
                <Routes>
                  <Route path={ROUTES.ROUTE_HOME} element={isAuthenticated ? <AuthenticatedHome /> : <Home />} />
                  <Route path={ROUTES.ROUTE_LOGIN} element={isAuthenticated ? <AuthenticatedHome /> : <LogIn />} />
                  <Route path={ROUTES.ROUTE_ADMIN} element={<ProtectedRoute><AdminMenu /></ProtectedRoute>} />
                  <Route path={ROUTES.ROUTE_ADMIN_PRODUCTS} element={<ProtectedRoute><AdminProducts /></ProtectedRoute>} />
                  <Route path={ROUTES.ROUTE_ADMIN_PRODUCTS_ADD} element={<ProtectedRoute><AdminProductsAdd /></ProtectedRoute>} />
                  <Route path={ROUTES.ROUTE_ADMIN_ORDERS} element={<ProtectedRoute><AdminOrders /></ProtectedRoute>} />
                  <Route path={ROUTES.ROUTE_ADMIN_PROFIL} element={<ProtectedRoute><AdminProfil /></ProtectedRoute>} />
                  <Route path={ROUTES.ROUTE_ADMIN_PROFIL_SHOPADD} element={<ProtectedRoute><AdminProfilShopAdd /></ProtectedRoute>} />
                  <Route path={ROUTES.ROUTE_URLFRIENDLY} element={<User />} />
                  <Route path={ROUTES.ROUTE_URLFRIENDLY_SHOP} element={<Shop />} />
                  <Route path={ROUTES.ROUTE_CART} element={<Cart />} />
                  <Route path={ROUTES.ROUTE_ADMIN_STEP_1} element={<ProtectedRoute><AdminStep1 /></ProtectedRoute>} />
                  <Route path={ROUTES.ROUTE_ADMIN_STEP_2} element={<ProtectedRoute><AdminStep2 /></ProtectedRoute>} />
                  <Route path={ROUTES.ROUTE_ADMIN_STEP_3} element={<ProtectedRoute><AdminStep3 /></ProtectedRoute>} />
                </Routes>
              </main>
              <Footer />
            </div>
          </AuthContext.Provider>
        </Router>
    </CartProvider>
  );
}

export default App;