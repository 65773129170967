import { getCookie, deleteCookie } from './cookie';
import { getUserInfo } from '../api/user';
import { toast } from 'react-toastify';

const checkAuthentication = async () => {
  const token = getCookie('auth_token'); // Get the 'auth_token' cookie value
  //console.log("Token: ", token);

  if (!token) {
    // If 'auth_token' cookie is not found, user is not authenticated
    return false;
  } else {
    const response = await getUserInfo(); // Fetch user information using an API call
    //console.log("Response from getUserInfo: ", response);

    if (!response.success) {
      // If the API call is unsuccessful (e.g., authentication failure)
      deleteCookie('auth_token'); // Delete the 'auth_token' cookie
      toast.error('Vous avez été déconnecté(e).'); // Display an error toast notification
      return false; // User is not authenticated
    }

    // If the API call is successful, user is authenticated
    return true;
  }
};

export default checkAuthentication;
