import React from 'react';
import ProductsAdd from '../products/ProductsAdd';
import { ROUTE_ADMIN_STEP_3 } from '../../../utils/routes';
import { Helmet } from 'react-helmet';

const Step2 = () => {
  return (
    <>
	      <Helmet>
				<title>Étape 2/2</title>
	      </Helmet>
		<section id="first-section">
          <h1>Nouveau produit</h1>
          <h2>Ajoutez votre premier produit !</h2>
        </section>
		<section id="second-section">
			<div className = "element-container">
          <ProductsAdd redirectAfterSubmit={ROUTE_ADMIN_STEP_3} />
          </div>
        </section>

    </>
  );
};

export default Step2;
