import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserInfo, updateUserInfo } from '../../../api/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { config } from '../../../config'; // Required to retrieve config.upload_url URL

const ProfilPersonalizationEdit = () => {
	const [loading, setLoading] = useState(true);
	const [userData, setUserData] = useState({
		image_logo: "",
		image_banner: ""
	});
	// Add the missing state variables
	const [imageLogo, setImageLogo] = useState(null);
	const [imageBanner, setImageBanner] = useState(null);

	useEffect(() => {
	    const fetchData = async () => {
	      const response = await getUserInfo();
	      if (response.success) {
	        const { image_logo, image_banner } = response.user;
	        setUserData({ image_logo, image_banner});
	        setLoading(false);
	      } else {
	        setErrorMessages(response.messages);
	        setLoading(false);
	      }
	    };
	
	    fetchData();
	  }, []);
	  if (loading) {
	    return <div>Loading...</div>;
	  }

  const handleImageUpload = (e, setImage) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

const handleSubmit = async (e) => {
  e.preventDefault();
  const formData = new FormData();
  if (imageBanner) formData.append('image_banner', imageBanner);
  if (imageLogo) formData.append('image_logo', imageLogo);

  try {
    const response = await updateUserInfo(formData);  // Remove userData.userId from here
    if (response.success) {
      toast.success("Votre image a été mise à jour !");
    } else {
      toast.error("Echec de la mise à jour");
    }
  } catch (error) {
    toast.error("Une erreur a eu lieu lors de la mise à jour de l'image");
  }
};

	return (
      <div className = "element-container">
        <h3>Personnalisation</h3>
		<form onSubmit={handleSubmit} className="element">

			<div>
				<label htmlFor="image-banner">Bannière</label>
           {userData.image_banner ? (
              <img src={`${config.upload_url}/${userData.image_banner}`} alt="Banner" />
            ) : (
              <img src={`${config.upload_url}/missing.jpg`} alt="Missing banner" />
            )}
           </div>
	
              <div className="form-group">
    
                <input
                  type="file"
                  id="image-banner"
                  name="image_banner"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e, setImageBanner)}
                />
              </div>

  <div>
                  <label htmlFor="image-logo">Logo</label>
              			{userData.image_logo ? (
              <img src={`${config.upload_url}/${userData.image_logo}`} alt="Logo" />
            ) : (
              <img src={`${config.upload_url}/missing.jpg`} alt="Missing logo" />
            )}</div>
              
              <div className="form-group">

                <input
                  type="file"
                  id="image-logo"
                  name="image_logo"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e, setImageLogo)}
                />
              </div>
            
              <button type="submit">Valider</button>
            </form>
      </div>
  );
};

export default ProfilPersonalizationEdit;
