import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ProfilUserEdit from './ProfilUserEdit';
import ProfilPersonalizationEdit from './ProfilPersonalizationEdit';
import ProfilShopsEdit from './ProfilShopsEdit';
import { ROUTE_ADMIN, ROUTE_ADMIN_PROFIL_SHOPADD } from '../../../utils/routes';
import { Helmet } from 'react-helmet';

const Profil = () => {
	  return (
	    <>
			<Helmet>
				<title>Mon profil</title>
			</Helmet>
			<section id="first-section">
				<h1>Profil</h1>
				<h2>Personnalisez votre profil.</h2>
				<Link to={ROUTE_ADMIN_PROFIL_SHOPADD}><button>Ajouter une boutique</button></Link>
			</section>
			<section id="second-section">
				<ProfilUserEdit />
				<ProfilPersonalizationEdit />
				<ProfilShopsEdit />
			</section>
	    </>
	  );
};

export default Profil;
